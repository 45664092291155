/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=5faf6b18";
import usersPermissions from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=5faf6b18";
import estadoDeReservas from "/admin/src/plugins/estado-de-reservas/dist/admin/index.mjs";
import reservasLibres from "/admin/src/plugins/reservas-libres/dist/admin/index.mjs";
import { renderAdmin } from "/admin/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=5faf6b18";

import customisations from "/admin/src/admin/app.tsx";

renderAdmin(document.getElementById("strapi"), {
  customisations,

  plugins: {
    "strapi-cloud": strapiCloud,
    "users-permissions": usersPermissions,
    "estado-de-reservas": estadoDeReservas,
    "reservas-libres": reservasLibres,
  },
});
